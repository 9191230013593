import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const RedeemConfirmationModal = ({ isOpen, onRequestClose, onConfirm, voucherName }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmation Modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <h2 style={{ margin: '0 0 20px 0' }}>Confirmation</h2>
      <p>Are you sure you want to redeem <strong>{voucherName}</strong>?</p>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <button onClick={onConfirm} style={{ backgroundColor: '#28a745', color: 'white', border: 'none', padding: '10px 15px', borderRadius: '5px', cursor: 'pointer' }}>
          Yes
        </button>
        <button onClick={onRequestClose} style={{ backgroundColor: '#dc3545', color: 'white', border: 'none', padding: '10px 15px', borderRadius: '5px', cursor: 'pointer' }}>
          No
        </button>
      </div>
    </Modal>
  );
};


export default RedeemConfirmationModal;
