import React from 'react';
import '../styles/Header.css';
import { faArrowLeft, faSignOut} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate,useLocation } from 'react-router-dom';

const Header = ({title, handleButtonBack}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token")
    navigate("/")
  };

  return (
       <div className="flex-header">
          <FontAwesomeIcon icon={faArrowLeft} className="left-icon" onClick={handleButtonBack} />
          <p className="title">{title}</p>
          <FontAwesomeIcon icon={faSignOut} className="right-icon" onClick={() => handleLogout()} />
      </div>
  );
};

export default Header;