import React, { useState, useEffect } from 'react';
import EmployeeInfo from '../components/EmployeeInfo';
import VoucherList from '../components/VoucherList';
import Header from '../components/Header';
import '../styles/App.css';
import { useLocation } from 'react-router-dom';
import '../styles/Main.css';
import { getEmployee, redeemVoucherWithoutPhoto } from '../networks/ApiServices';
import { useNavigate } from 'react-router-dom';

const EmployeePage = () => {
  const location = useLocation();
  const employeeId = location.state?.employeeId;
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (!employeeId) {
      navigate('/home');
      return;
    }

    const fetchEmployeeData = async () => {
      try {
        const response = await getEmployee(employeeId);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [employeeId, navigate]);

  const doRedeem = async (voucherId) => {

    try {
      await redeemVoucherWithoutPhoto(voucherId, employeeId, '');
      alert('Voucher redeemed successfully');
      navigate(0);

    } catch (error) {
      alert('Error redeeming voucher');
    }
   

  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Error loading data.</div>;
  }


  const handleButtonBack = () => {
    navigate(-1)
  };

  return (
    <div className="flex-container">
      <div className="flex-subcontainer">
        <Header title="Employee Details" handleButtonBack={handleButtonBack}/>
        <div style={{padding: 10}}>
          <EmployeeInfo employee={data.employee} />
          <VoucherList vouchers={data.voucher_list} doRedeem={doRedeem} />
        </div>
      </div>
    </div>
  );
};

export default EmployeePage;
 