import CryptoJS from 'crypto-js';

class AESUtil {
  static KEY = CryptoJS.enc.Utf8.parse("GnpM@2XSK*a&F&&K");

  static encryptBase64(plaintext) {
    const encrypted = AESUtil.encrypt(plaintext);
    return CryptoJS.enc.Base64.stringify(encrypted);
  }

  static encryptHexStr(plaintext) {
    const encrypted = AESUtil.encrypt(plaintext);
    return CryptoJS.enc.Hex.stringify(encrypted);
  }

  static decryptBase64(ciphertext) {
    const decoded = CryptoJS.enc.Base64.parse(ciphertext);
    return AESUtil.decrypt(decoded);
  }

  static decryptHexStr(ciphertext) {
    const decoded = CryptoJS.enc.Hex.parse(ciphertext);
    return AESUtil.decrypt(decoded);
  }

  static encrypt(plaintext) {
    const key = AESUtil.KEY;
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext;
  }

  static decrypt(ciphertext) {
    const key = AESUtil.KEY;
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypted);
  }
}

export default AESUtil;
