import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setupAxiosInterceptors } from './ApiServices';

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const currentPath = location.pathname;

    if (currentPath === '/' && token) {
      navigate('/home');
    } else if (!token && currentPath !== '/') {
      navigate('/');
    } else if (token) {
      setupAxiosInterceptors(navigate);
    }
  }, [navigate, location]);
};

export default useAuth;
