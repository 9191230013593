import React, { useRef, useState, useCallback} from 'react';
import Webcam from 'react-webcam';

const PhotoCapture = ({ voucherName, onCaptureConfirm, onCancel }) => {
  const webcamRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [facingMode, setFacingMode] = useState('user'); // 'user' for front, 'environment' for back
  const [deviceId, setDeviceId] = useState(null);
  const [devices, setDevices] = useState([]);

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  );

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
  };

  const switchCamera = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === 'user' ? 'environment' : 'user'
    );
  };

  const videoConstraints = {
    facingMode: facingMode,
  };

  const confirmPhoto = () => {
    onCaptureConfirm(photo);
  };

  return (
    <div>
      <h2>Take a Photo</h2>
      <p>Take a photo proof for redeem <strong>{voucherName}</strong></p>

      {photo ? (
        <div>
          <img src={photo} alt="Captured" style={{ width: '100%' }} />
          <button onClick={confirmPhoto}>Yes</button>
          <button onClick={() => setPhoto(null)}>No</button>
        </div>
      ) : (
        <div>
          <button onClick={switchCamera}>
            Switch to {facingMode === 'user' ? 'Back' : 'Front'} Camera
          </button>
          <Webcam
            videoConstraints={videoConstraints}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
          />
          <button onClick={capturePhoto}>Capture</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default PhotoCapture;
