import React, { useState, useEffect } from 'react';
import '../styles/Main.css';
import '../styles/LoadingCircle.css';
import '../styles/Table.css';
import { QrReader } from 'react-qr-reader';
import AESUtil from '../utils/AESUtil';
import { getEmployee, searchEmployees, getAdmin } from '../networks/ApiServices';
import { useNavigate,useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faSignOut} from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';

const HomePage = () => {
  const location = useLocation();

  const initialLayout = location.state?.layout || sessionStorage.getItem('currentLayout') || 'camera';
  const initialNik = location.state?.nik || sessionStorage.getItem('saveNik') || '';
  const initialEmail = location.state?.email || sessionStorage.getItem('saveEmail') || '';
  const initialPhone = location.state?.phone || sessionStorage.getItem('savePhone') || '';


  const [employeeList, setEmployeeList] = useState([]);
  const [facingMode, setFacingMode] = useState('environment'); // State for camera direction
  const [currentLayout, setCurrentLayout] = useState(initialLayout); // State for managing current layout
  const [nik, setNik] = useState(initialNik);
  const [email, setEmail] = useState(initialEmail);
  const [phone, setPhone] = useState(initialPhone);
  const [loading, setLoading] = useState(false); // State for loading animation
  const [admin, setAdmin] = useState(); // State for loading animation

  const navigate = useNavigate();

  useEffect(() => {
    // Save the current layout to sessionStorage whenever it changes
    sessionStorage.setItem('currentLayout', currentLayout);
    sessionStorage.setItem('saveNik', nik);
    sessionStorage.setItem('saveEmail', email);
    sessionStorage.setItem('savePhone', phone);

    const fetchAdmin = async () => {
      try {
        const response = await getAdmin();
        setAdmin(response.data);
      } catch (error) {
        console.error('Error', error);
      } finally {
      }
    };

    fetchAdmin();

  }, [currentLayout, nik, email, phone]);

  const goToEmployeePage = (employeeId) => {
    navigate('/employee', {
      state: {
        employeeId
      },
    });
  };


  const toggleCamera = () => {
    setFacingMode(prevMode => (prevMode === 'environment' ? 'user' : 'environment'));
  };

  const analyzeBarcode = (result) => {
      try {
        var res = AESUtil.decryptHexStr(result["text"])
        console.log(res)
        const parsedJsonObject = JSON.parse(res);
        const nik = parsedJsonObject.user_identity
        console.log(parsedJsonObject.user_identity);
        getEmployee(null, nik)
        .then(response => {
          console.log(response.data);
          const employeeId = response.data.employee.id
          goToEmployeePage(employeeId)
        })
        .catch(error => {
          alert(error.response.data.error)
        });
      } catch (error) {
        alert('Barcode tidak cocok ('+error+')' )
      }
     
  };
  const handleNikSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    searchEmployees(nik, null, null)
      .then(response => {
        setEmployeeList(response.data);
        setLoading(false);
      })
      .catch(error => {
        alert(error.response.data.error);
        setLoading(false);
      });
  };

  const handleEmailSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    searchEmployees(null, email, null)
      .then(response => {
        setEmployeeList(response.data);
        setLoading(false);
      })
      .catch(error => {
        alert(error.response.data.error);
        setLoading(false);
      });
  };

  const handlePhoneSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    searchEmployees(null, null, phone)
      .then(response => {
        setEmployeeList(response.data);
        setLoading(false);
      })
      .catch(error => {
        alert(error.response.data.error);
        setLoading(false);
      });
  };

  const clearInput = (setter) => {
    setter('');
  };

  const handleLogout = () => {
    localStorage.removeItem("token")
    navigate("/")
  };

  const handleButtonBack = () => {
    navigate(-1)
  };

  return (
    <div className="flex-container">
      
      <div className="flex-subcontainer">
      <Header title="Home" handleButtonBack={handleButtonBack}/>

      
          {admin ?  (
          <div style={{padding: 10}}>
            <p>Admin Name: {admin.name}</p>
            <p>Admin Email: {admin.email}</p>
          </div>
          ):(<></>)}
          <div className="navigation-bar">
            <button
              className={currentLayout === 'camera' ? 'active' : ''}
              onClick={() => setCurrentLayout('camera')}
            >
              Scan Barcode
            </button>
            <button
              className={currentLayout === 'inputNik' ? 'active' : ''}
              onClick={() => setCurrentLayout('inputNik')}
            >
              Find User
            </button>
          </div>

          {currentLayout === 'camera' && (
              <div className="qr-reader-container">
                <QrReader
                  key={facingMode} // Add this line to force re-render
                  onResult={(result, error) => {
                    if (!!result) {
                      analyzeBarcode(result);
                    }
                  }}
                  constraints={{ facingMode,  aspectRatio: { ideal: 1 }            }}
                />
                            <div className="rectangle-overlay"></div>

                <div className="div-container">
                  <button className="switch-camera-button" onClick={toggleCamera}>
                    Switch to {facingMode === 'environment' ? 'Front' : 'Back'} Camera
                  </button>
                </div>
              </div>
            )}
          <div style={{padding: 10}}>
            {currentLayout === 'inputNik' && (
                    <div className="form-container">
                      <form onSubmit={handleNikSearch} className="input-form">
                        <label>Input NIK:</label>
                        <div>
                          <input type="text" value={nik} onChange={(e) => setNik(e.target.value)} />
                          {nik && <FontAwesomeIcon icon={faTimes} onClick={() => clearInput(setNik)} className="clear-icon" />}
                        </div>
                        <button type="submit" disabled={loading}>Search by NIK</button>
                      </form>
                      <form onSubmit={handleEmailSearch} className="input-form">
                        <label>Input Email:</label>
                        <div>
                          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                          {email && <FontAwesomeIcon icon={faTimes} onClick={() => clearInput(setEmail)} className="clear-icon" />}
                        </div>
                        <button type="submit" disabled={loading}>Search by Email</button>
                      </form>
                      <form onSubmit={handlePhoneSearch} className="input-form">
                        <label>Input Phone:</label>
                        <div>
                          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                          {phone && <FontAwesomeIcon icon={faTimes} onClick={() => clearInput(setPhone)} className="clear-icon" />}
                        </div>
                        <button type="submit" disabled={loading}>Search by Phone</button>
                      </form>
                      

                      {loading ? (
                        <div className="loading-container">
                          <div className="loading-circle"></div>
                        </div>
                      ) : employeeList.length > 0 ? (
                        <div className="table-container">
                          <table className="responsive-table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>NIK</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeList.map(employee => (
                              <tr key={employee.id}>
                                <td>{employee.name}</td>
                                <td>{employee.nik}</td>
                                <td>{employee.email}</td>
                                <td>{employee.phone}</td>
                                <td>
                                  <button onClick={() => goToEmployeePage(employee.id)}>Details</button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="no-data">Data tidak ditemukan</div>
                      )}
                    </div>
                  )}
          </div>      
      </div>
    </div>
  );
};
export default HomePage;