import React from 'react';
import ReactDOM from 'react-dom';
import HomePage from './pages/HomePage';
import EmployeePage from './pages/EmployeePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import useAuth from './networks/UseAuth';

const App = () => {
  useAuth(); // Call the custom hook to handle authentication and interceptor setup

  return (
    <div>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/employee" element={<EmployeePage />} />
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </div>
  );
};

const Main = () => (
  <Router>
    <App />
  </Router>
);

ReactDOM.render(<Main />, document.getElementById('root'));

// serviceWorker.unregister();