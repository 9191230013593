import React from 'react';

const EmployeeInfo = ({ employee }) => {
  return (
    <div>
      <h2>Employee Information</h2>
      <p><strong>Name:</strong> {employee.name}</p>
      <p><strong>Email:</strong> {employee.email}</p>
      <p><strong>NIK:</strong> {employee.nik}</p>
      <p><strong>Phone:</strong> {employee.phone}</p>
      <p><strong>Batch:</strong> {employee.batch}</p>
    </div>
  );
};

export default EmployeeInfo;