import React, { useState } from 'react';
import '../styles/Main.css';
import { adminLogin } from '../networks/ApiServices';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginForm.css';

const LoginPage = () => {
const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true)
    adminLogin(email, password)
      .then(response => {
        localStorage.setItem('token', response.data.token); // Assuming the response contains a token
        setLoading(false);
        navigate("/home")
      })
      .catch(error => {
        console.log(JSON.stringify(error.response.data))
        if(error.response.data.email){
          alert(error.response.data.email)
        }else if(error.response.data.password){
          console.log(error.response.data.password)
          alert(error.response.data.password)
        }else {
          alert(JSON.stringify(error.response.data))
        }
        setLoading(false);
      });
};

  return (
    <div className="flex-container">
      <div className="flex-subcontainer">
        <div id="login-form">
        <h1>Login</h1>
        <div className="form">
          <label htmlFor="username">Username:</label>
          <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          <label htmlFor="password">Password:</label>
          <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="login-input"
            />
          <button onClick={handleLogin} className="login-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};
export default LoginPage;